<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M20 11.983a.5.5 0 0 0 .5-.5v-2.62a.5.5 0 0 0-.5-.5h-2.3a7.843 7.843 0 0 0-.588-1.432l1.627-1.627a.5.5 0 0 0 0-.707l-2.337-2.336a.5.5 0 0 0-.707 0l-1.63 1.63c-.445-.227-1-.454-1.427-.59V1a.5.5 0 0 0-.5-.5H8.862a.5.5 0 0 0-.5.5v2.302c-.437.138-.983.36-1.427.589L5.304 2.26a.5.5 0 0 0-.707 0L2.26 4.597a.5.5 0 0 0 0 .707l1.631 1.632a10.45 10.45 0 0 0-.586 1.426H1a.5.5 0 0 0-.5.5v2.62a.5.5 0 0 0 .5.5h2.254c.138.763.368 1.514.64 2.08L2.26 15.695a.5.5 0 0 0 0 .707l2.338 2.336a.5.5 0 0 0 .707 0l1.635-1.635c.166.079.354.157.568.239.038.014.535.191.855.31V20a.5.5 0 0 0 .5.5h3.276a.5.5 0 0 0 .5-.5v-2.346c.32-.12.819-.297.854-.31.214-.082.402-.16.568-.24l1.636 1.635a.5.5 0 0 0 .707 0l2.336-2.336a.5.5 0 0 0 0-.707l-1.62-1.622c.331-.582.514-1.134.643-2.091H20zm-3.95 5.696l-1.545-1.543a.5.5 0 0 0-.614-.073 4.729 4.729 0 0 1-.755.346c.05-.02-1.066.377-1.238.47a.5.5 0 0 0-.26.44V19.5H9.362v-2.182a.5.5 0 0 0-.26-.439c-.172-.093-1.283-.487-1.24-.47a4.723 4.723 0 0 1-.754-.347.5.5 0 0 0-.615.073L4.95 17.679l-1.63-1.63 1.544-1.544a.5.5 0 0 0 .073-.614c-.328-.537-.633-1.525-.76-2.475a.5.5 0 0 0-.496-.433H1.5v-1.62h2.181a.5.5 0 0 0 .486-.383c.115-.474.476-1.39.77-1.871a.5.5 0 0 0-.073-.614L3.321 4.95l1.63-1.63 1.543 1.543a.5.5 0 0 0 .614.073c.502-.306 1.417-.67 1.86-.766a.5.5 0 0 0 .394-.489V1.5h2.276v2.182a.5.5 0 0 0 .405.491c.375.073 1.35.46 1.848.765a.5.5 0 0 0 .614-.074l1.544-1.543 1.63 1.63-1.544 1.543a.5.5 0 0 0-.073.614c.34.556.61 1.217.77 1.873a.5.5 0 0 0 .485.381H19.5v1.62h-2.182a.5.5 0 0 0-.497.448c-.124 1.166-.277 1.655-.619 2.23-.034.059-.067.112-.14.231a.5.5 0 0 0 .073.614l1.544 1.544-1.63 1.63z"
    />
    <path
      d="M14.603 10.5a4.104 4.104 0 1 1-8.207 0 4.104 4.104 0 0 1 8.207 0zm-1 0a3.104 3.104 0 1 0-6.207 0 3.104 3.104 0 0 0 6.207 0z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
